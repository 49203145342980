import { useEffect, useState, forwardRef, useRef } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
//import CardMedia from "@mui/material/CardMedia";
import defaulTheme from "../../components/themes/defaultTheme";
import { Formik, Form } from "formik";
import * as authActions from "../../redux/actions/auth.action";
//import BackgroundImage from "../../assets/images/bg-02.jpg";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import * as Yup from "yup";
import { Turnstile } from "@marsidev/react-turnstile";
import FormHelperText from "@mui/material/FormHelperText";
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore, collection, addDoc, Timestamp } from "firebase/firestore"

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      fontWeight={700}
      {...props}
    >
      Copyright &copy; 2022
    </Typography>
  );
}

const theme = createTheme(defaulTheme);

export default function SignIn() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const location = useLocation();
  const [openAlert, setOpenAlert] = useState(false);
  const [messageAlert, setMessageAlert] = useState("");
  const [severity, setSeverity] = useState("");
  const [backDrop, setBackDrop] = useState(false);
  const ref = useRef(null);
  // TODO: Add SDKs for Firebase products that you want to use
  // https://firebase.google.com/docs/web/setup#available-libraries

  // Your web app's Firebase configuration
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
  const firebaseConfig = {
    apiKey: "AIzaSyAzO26qH93zm91xDdu_nMhFbU6VYfhWqUg",
    authDomain: "akpong101-7c043.firebaseapp.com",
    projectId: "akpong101-7c043",
    storageBucket: "akpong101-7c043.appspot.com",
    messagingSenderId: "615149345202",
    appId: "1:615149345202:web:e6a7c314514851dd572cab",
    measurementId: "G-CJ85WT69P8",
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const store = getFirestore(app);

  useEffect(() => {
    console.log(store)
    const authUser = localStorage.getItem("authUser");
    if (authUser) {
      navigate("/Home");
    } else {
      navigate("/SignIn");
    }
  }, [navigate, dispatch, store]);

  const handleClickAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  const handleSignUpClick = () => {
    navigate("/SignUp");
  };

  const handleSubmitAddDoc = async (e) => {
    e.preventDefault()
    try {
      await addDoc(collection(store, 'akpong101'), {
        title: "akpong",
        description: "akpong",
        completed: false,
        created: Timestamp.now()
      })
    } catch (err) {
      alert(err)
    }
  }

  //const handleCaptchaClick = () => {
  //dispatch(authActions.hand());
  //};
  // const handleAutologinClick = () => {
  //   dispatch(authActions.autoLogin())
  //     .then((res) => {
  //       setBackDrop(true);
  //       console.log(res);
  //       if (res.isSuccess) {
  //         setTimeout(function () {
  //           setBackDrop(false);
  //           navigate("/Home");
  //         }, 1000);
  //       } else {
  //         setMessageAlert(res.message);
  //         setSeverity("error");
  //         handleClickAlert();
  //         setBackDrop(false);
  //         setTimeout(function () {
  //           handleCloseAlert();
  //         }, 3000);
  //       }
  //     })
  //     .catch((res) => {
  //       setMessageAlert(res.message);
  //       setSeverity("error");
  //       handleClickAlert();
  //       setBackDrop(false);
  //       setTimeout(function () {
  //         handleCloseAlert();
  //       }, 3000);
  //     });
  // };

  const validationSchema = Yup.object().shape({
    userName: Yup.string().required("Username is required").nullable(),
    password: Yup.string().required("Password is required").nullable(),
    captcha: Yup.string().required("Captcha is required").nullable(),
  });

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
          width: "100%",
          backgroundColor: "#829460",
          //backgroundImage: `url(${BackgroundImage})`,
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
          padding: "15px",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={backDrop}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            open={openAlert}
            onClose={handleCloseAlert}
          >
            <Alert
              onClose={handleCloseAlert}
              severity={severity}
              sx={{ width: "100%" }}
            >
              {messageAlert}
            </Alert>
          </Snackbar>
          <Box
            sx={{
              marginTop: 6,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Card
              variant="outlined"
              sx={{ boxShadow: 3, borderRadius: "16px", padding: 1 }}
            >
              <CardHeader sx={{ color: "#829460" }} title="SIGNIN" />
              <CardContent sx={{ padding: "16px 16px 8px 16px !important" }}>
                <Formik
                  enableReinitialize
                  initialValues={{
                    userName: "",
                    password: "",
                    captcha: "",
                  }}
                  onSubmit={(values) => {
                    console.log(values);
                    let formData = new FormData();
                    formData.append("userName", values.userName);
                    formData.append("password", values.password);
                    dispatch(authActions.signIn(formData))
                      .then((res) => {
                        setBackDrop(true);
                        console.log(res);
                        if (res.isSuccess) {
                          setTimeout(function () {
                            setBackDrop(false);
                            navigate("/Home");
                          }, 1000);
                        } else {
                          setMessageAlert(res.message);
                          setSeverity("error");
                          handleClickAlert();
                          setBackDrop(false);
                          setTimeout(function () {
                            handleCloseAlert();
                            //navigate("/Login");
                          }, 3000);
                        }
                      })
                      .catch((res) => {
                        setMessageAlert(res.message);
                        setSeverity("error");
                        handleClickAlert();
                        setBackDrop(false);
                        setTimeout(function () {
                          handleCloseAlert();
                          //navigate("/Login");
                        }, 3000);
                      });
                  }}
                  validationSchema={validationSchema}
                >
                  {(props) => {
                    const {
                      values,
                      touched,
                      errors,
                      handleChange,
                      handleSubmit,
                      setFieldValue,
                    } = props;
                    return (
                      <Form ref={ref} onSubmit={handleSubmit}>
                        {/* <Typography component="h1" variant="h5">
                          Please Sign In
                        </Typography> */}
                        <Grid container spacing={0.5}>
                          <Grid item xs={12} md={12}>
                            <TextField
                              id="userName"
                              name="userName"
                              fullWidth
                              margin="dense"
                              size="small"
                              autoFocus
                              value={values.userName}
                              onChange={handleChange}
                              placeholder="USERNAME"
                              helperText={
                                touched.userName ? errors.userName : ""
                              }
                              error={
                                touched.userName && Boolean(errors.userName)
                              }
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  borderRadius: "16px",
                                  "& fieldset": {
                                    border: "2px solid rgba(0, 0, 0, 0.12)",
                                  },
                                  "&:hover fieldset": {
                                    border: "2px solid #90A17D",
                                  },
                                  "&.Mui-focused fieldset": {
                                    border: "2px solid #829460",
                                  },
                                },
                                "& .MuiFormHelperText-root.Mui-error": {
                                  fontWeight: 700,
                                  marginTop: 0,
                                },
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <TextField
                              id="password"
                              name="password"
                              type="password"
                              fullWidth
                              margin="dense"
                              size="small"
                              autoComplete="current-password"
                              value={values.password}
                              onChange={handleChange}
                              placeholder="PASSWORD"
                              helperText={
                                touched.password ? errors.password : ""
                              }
                              error={
                                touched.password && Boolean(errors.password)
                              }
                              sx={{
                                marginTop: 0,
                                "& .MuiOutlinedInput-root": {
                                  borderRadius: "16px",
                                  "& fieldset": {
                                    border: "2px solid rgba(0, 0, 0, 0.12)",
                                  },
                                  "&:hover fieldset": {
                                    border: "2px solid #90A17D",
                                  },
                                  "&.Mui-focused fieldset": {
                                    border: "2px solid #829460",
                                  },
                                },
                                "& .MuiFormHelperText-root.Mui-error": {
                                  fontWeight: 700,
                                  marginTop: 0,
                                },
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <Turnstile
                              ref={ref}
                              siteKey="3x00000000000000000000FF"
                              //siteKey="0x4AAAAAAACxFsgBvMQ2a8qg"
                              className="fixed"
                              style={{ width: "auto", height: "auto" }}
                              options={{
                                action: "submit-form",
                                theme: "light",
                                size: "normal",
                              }}
                              scriptOptions={{
                                appendTo: "body",
                              }}
                              onError={(e) => console.log("onError", e)}
                              onExpire={(ex) => console.log("onExpire", ex)}
                              onSuccess={(s) => setFieldValue("captcha", s)}
                            />
                            <FormHelperText
                              id="captcha-helper-text"
                              sx={{
                                color: "#d32f2f",
                                fontWeight: 700,
                                fontSize: "0.75rem",
                                lineHeight: 1.66,
                                textAlign: "left",
                                marginTop: "0px",
                                marginRight: "14px",
                                marginBottom: 0,
                                marginLeft: "14px",
                              }}
                            >
                              {touched.captcha ? errors.captcha : ""}
                            </FormHelperText>
                            <TextField
                              id="captcha"
                              name="captcha"
                              fullWidth
                              margin="dense"
                              size="small"
                              value={values.captcha || ""}
                              helperText={touched.captcha ? errors.captcha : ""}
                              error={touched.captcha && Boolean(errors.captcha)}
                              sx={{
                                display: "none",
                                "& .MuiOutlinedInput-root": {
                                  borderRadius: "16px",
                                  "& fieldset": {
                                    border: "2px solid rgba(0, 0, 0, 0.12)",
                                  },
                                  "&:hover fieldset": {
                                    border: "2px solid #90A17D",
                                  },
                                  "&.Mui-focused fieldset": {
                                    border: "2px solid #829460",
                                  },
                                },
                                "& .MuiFormHelperText-root.Mui-error": {
                                  fontWeight: 700,
                                  marginTop: 0,
                                },
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <Button
                              type="submit"
                              fullWidth
                              variant="contained"
                              color="primary"
                              sx={{
                                mb: 1,
                                mt: 1,
                                borderRadius: "16px",
                              }}
                            >
                              <Typography variant="body2" fontWeight={700}>
                                SignIn
                              </Typography>
                            </Button>
                          </Grid>
                          <Grid item xs={12} md={6}></Grid>
                          <Grid item xs={12} md={6}>
                            <Button
                              type="button"
                              fullWidth
                              sx={{
                                borderRadius: "16px",
                              }}
                              onClick={(e) => handleSubmitAddDoc(e)}
                            >
                              <Typography variant="body2" fontWeight={700}>
                                Register
                              </Typography>
                            </Button>
                          </Grid>
                        </Grid>
                      </Form>
                    );
                  }}
                </Formik>
              </CardContent>
            </Card>
          </Box>
          <Copyright sx={{ mt: 8, mb: 4 }} />
        </Container>
      </Box>
    </ThemeProvider>
  );
}
