import React from "react";
import MuiAppBar from "@mui/material/AppBar";
import { styled, alpha } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
//import Typography from "@mui/material/Typography";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";

const drawerWidth = 240;

const CustomAppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: "20px",
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(1),
  marginLeft: "0px !important",
  width: "100%",
  [theme.breakpoints.up("xs")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(0)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

export default function Header(props) {
  const { toggleDrawer, open, handleDrawerToggle, mobileOpen, isMdUp } = props;

  return isMdUp ? (
    <CustomAppBar position="absolute" open={open}>
      <Toolbar
        sx={{
          pr: "24px", // keep right padding when drawer closed
        }}
      >
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={toggleDrawer}
          sx={{
            marginLeft: "-9px",
            marginRight: "36px",
            ...(open && { display: "none" }),
          }}
        >
          <MenuIcon />
        </IconButton>
        <Search>
          <StyledInputBase
            placeholder="Search TicketId"
            onChange={(e) => {
              console.log(e);
            }}
            inputProps={{ "aria-label": "search" }}
          />
        </Search>
        <IconButton color="inherit">
          <SearchIcon />
        </IconButton>
        <Box sx={{ flexGrow: 1 }} />
      </Toolbar>
    </CustomAppBar>
  ) : (
    <AppBar
      position="fixed"
      sx={{
        p: 0.5,
        width: { sm: `calc(100%)` },
        //width: { sm: `calc(100% - ${drawerWidth}px)` },
        //ml: { sm: `${drawerWidth}px` },
      }}
    >
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ ...(mobileOpen && { display: "none" }) }}
        >
          <MenuIcon />
        </IconButton>
        {/* <Typography component="div" noWrap sx={{ p: 1, pt: 1.5, pb: 0.5 }}>
          <img
            src={require("../../assets/images/logoSmartCare.png")}
            alt="SmartCare"
            title="SmartCare"
            style={{ width: "90px", height: "39px" }}
          />
        </Typography> */}
        <Search>
          <StyledInputBase
            placeholder="Search TicketId"
            onChange={(e) => {
              console.log(e);
            }}
            inputProps={{ "aria-label": "search" }}
          />
        </Search>
        <IconButton color="inherit">
          <SearchIcon />
        </IconButton>
        <Box sx={{ flexGrow: 1 }} />
        {/* <IconButton color="inherit">
          <Badge badgeContent={4} color="secondary">
            <NotificationsIcon />
          </Badge>
        </IconButton> */}
      </Toolbar>
    </AppBar>
  );
}
