import { combineReducers } from "redux";
import HomeReducer from "../reducers/home.reducer";
import UserReducer from "../reducers/user.reducer";

const rootReducer = combineReducers({
  homeReducer: HomeReducer,
  userReducer: UserReducer,
});

export default rootReducer;
