export const HOME_FETCHING = "HOME_FETCHING";
export const HOME_SUCCESS = "HOME_SUCCESS";
export const HOME_FAILED = "HOME_FAILED";

export const USER_FETCHING = "USER_FETCHING";
export const USER_SUCCESS = "USER_SUCCESS";
export const USER_FAILED = "USER_FAILED";
export const USER_BY_ID_SUCCESS = "USER_BY_ID_SUCCESS";

export const SIGNIN_USER_FETCHING = "SIGNIN_USER_FETCHING";
export const SIGNIN_USER_SUCCESS = "SIGNIN_USER_SUCCESS";
export const SIGNIN_USER_FAILED = "SIGNIN_USER_FAILED";
export const SIGNOUT_USER_SUCCESS = "SIGNOUT_USER_SUCCESS";

export const apiUrl = process.env.REACT_APP_API;
export const server = {
  HOME_URL: `Home`,
  USER_URL: `User`,
  ACCOUNTS_URL: `Accounts`,
};
