import { HOME_FETCHING, HOME_SUCCESS, HOME_FAILED } from "../../constants";

const initialState = {
  result: null,
  isFetching: false,
  isError: false,
};

const Home = (state = initialState, { type, payload }) => {
  switch (type) {
    case HOME_FETCHING:
      return { ...state, isFetching: true, isError: false, result: null };
      case HOME_SUCCESS:
        return { ...state, isFetching: false, isError: false, result: payload };
      case HOME_FAILED:
        return { ...state, isFetching: false, isError: true, result: payload };
      default:
        return state;
  }
};

export default Home;
