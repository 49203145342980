import {
    SIGNIN_USER_FETCHING,
    SIGNIN_USER_SUCCESS,
    SIGNIN_USER_FAILED,
    SIGNOUT_USER_SUCCESS,
    server,
  } from "../../constants";
  import { httpClient } from "../../utils/Api";
  
  export const setSignInFetchingToState = () => ({
    type: SIGNIN_USER_FETCHING,
  });
  
  export const setSignInSuccessToState = (payload) => ({
    type: SIGNIN_USER_SUCCESS,
    payload,
  });

  export const setSignOutSuccessToState = (payload) => ({
    type: SIGNOUT_USER_SUCCESS,
    payload,
  });
  
  export const setSignInFailedToState = (payload) => ({
    type: SIGNIN_USER_FAILED,
    payload,
  });
  
  export const signIn = (values) => {
    return async (dispatch) => {
      try {
        dispatch(setSignInFetchingToState());
        const res = await httpClient.post(
          `${process.env.REACT_APP_API}/${server.ACCOUNTS_URL}/Authentication`, values
        );
        console.log(res.data);
        if (res.data.isSuccess) {
          dispatch(setSignInSuccessToState(res.data.data));
          localStorage.setItem("authUser", JSON.stringify(res.data.data));
        } else {
          dispatch(setSignInFailedToState(res.data.message));
        }
        return res.data;
      } catch (e) {
        dispatch(setSignInFailedToState("Failed"));
      }
    };
  };

  export const Captcha = () => {
    return async (dispatch) => {
      try {
        //dispatch(setSignInFetchingToState());
        let formData = new FormData();
        formData.append('secret', encodeURIComponent("0x4AAAAAAACj-mvR48DEPJmkLUaHf8DMW1E"));
        formData.append('response', encodeURIComponent("0x4AAAAAAACj-ibEfQTdggnL"));
        const res = await httpClient.post(
          `https://challenges.cloudflare.com/turnstile/v0/siteverify`, formData, {
            headers: {
              'Access-Control-Allow-Origin': '*',
              'content-type': 'application/x-www-form-urlencoded'
            },
            mode: 'no-cors'
          }
        );
        console.log(res);
        // if (res.data.isSuccess) {
        //   dispatch(setSignInSuccessToState(res.data.data));
        //   localStorage.setItem("authUser", JSON.stringify(res.data.data));
        // } else {
        //   dispatch(setSignInFailedToState(res.data.message));
        // }
        return res;
      } catch (e) {
        dispatch(setSignInFailedToState(e));
      }
    };
  };


  export const signUp = (values) => {
    return async (dispatch) => {
      try {
        dispatch(setSignInFetchingToState());
        const res = await httpClient.post(
          `${process.env.REACT_APP_API}/${server.ACCOUNTS_URL}/SignUp`, values
        );
        console.log(res.data);
        if (res.data.isSuccess) {
          dispatch(setSignInSuccessToState(res.data.data));
          //localStorage.setItem("authUser", JSON.stringify(res.data.data));
        } else {
          dispatch(setSignInFailedToState(res.data.message));
        }
        return res.data;
      } catch (e) {
        dispatch(setSignInFailedToState("Failed"));
      }
    };
  };

  export const signOut = () => {
    return (dispatch) => {
      dispatch(setSignInFetchingToState());
      localStorage.removeItem("authUser");
      dispatch(setSignOutSuccessToState());
      return true;
    };
  };

  // `pages/api/verify.js`
// this is an example of a next.js api route
// this code runs on the server
const endpoint = 'https://challenges.cloudflare.com/turnstile/v0/siteverify'
const secret = '3x0000000000000000000000000000000AA'

export async function handler(request, response) {
  const body = `secret=${encodeURIComponent(secret)}&response=${encodeURIComponent(request.body.token)}`

  const res = await fetch(endpoint, {
    method: 'POST',
    body,
    headers: {
      'content-type': 'application/x-www-form-urlencoded'
    }
  })

  const data = await res.json()
  return response.json(data)
}
  