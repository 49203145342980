import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import Header from "../Header/Header";
import SideNav from "../SideNav/SideNav";
import Footer from "../Footer/Footer";
import { Outlet } from "react-router-dom";
import defaulTheme from "../themes/defaultTheme";
import "../../assets/style";
import useMediaQuery from "@mui/material/useMediaQuery";
import Fab from "@mui/material/Fab";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Fade from "@mui/material/Fade";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import PropTypes from "prop-types";
import Tooltip from "@mui/material/Tooltip";

function ScrollTop(props) {
  const { children, scrollTarget } = props;
  const trigger = useScrollTrigger({
    target: scrollTarget,
    disableHysteresis: true,
    threshold: 50,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document.body).querySelector(
      "#back-to-top-anchor"
    );

    if (anchor) {
      anchor.scrollIntoView({
        block: "center",
        behavior: "smooth",
      });
    }
  };

  return (
    <Fade in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: "fixed", bottom: 56, right: 26 }}
      >
        {children}
      </Box>
    </Fade>
  );
}

ScrollTop.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

const mdTheme = createTheme(defaulTheme);
const handleResize = () => {
  if (window.innerWidth < 720) {
    return false;
  } else {
    return true;
  }
};

export default function Layout(props) {
  const [open, setOpen] = useState(handleResize);
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const [scrollTarget, setScrollTarget] = useState(undefined);
  const scrollTrigger = useScrollTrigger({ target: scrollTarget });
  const [mobileOpen, setMobileOpen] = useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const isMdUp = useMediaQuery(mdTheme.breakpoints.up("md"));

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const anchor = document.body.querySelector("#back-to-top-anchor");

    if (anchor) {
      anchor.scrollIntoView({
        block: "center",
        behavior: "smooth",
      });
    }
    const authUser = localStorage.getItem("authUser");
    if (!authUser) {
      navigate("/SignIn");
    }
  }, [navigate, dispatch]);

  const notificationsReducer = useSelector(
    (state) => state.notificationsReducer
  );

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: "flex", height: "100vh" }}>
        <CssBaseline />
        <Header
          toggleDrawer={toggleDrawer}
          handleDrawerToggle={handleDrawerToggle}
          open={open}
          isMdUp={isMdUp}
          shrink={scrollTrigger}
          notificationsReducer={notificationsReducer}
        />
        <SideNav
          toggleDrawer={toggleDrawer}
          handleDrawerToggle={handleDrawerToggle}
          open={open}
          mobileOpen={mobileOpen}
          isMdUp={isMdUp}
        />
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
          ref={(node) => {
            if (node) {
              setScrollTarget(node);
            }
          }}
        >
          <Toolbar id="back-to-top-anchor" />
          <Container
            maxWidth={false}
            sx={{
              mt: 3,
              mb: 3,
              minHeight: isMdUp
                ? "calc(100vh - calc(5rem + 1px) - calc(5rem + 1px))"
                : "calc(100vh - calc(4.75rem + 1px) - calc(4.75rem + 1px))",
            }}
          >
            <Outlet />
            <ScrollTop {...props} scrollTarget={scrollTarget}>
              <Fab color="primary" size="small" aria-label="scroll back to top">
                <Tooltip title={"Scroll back to top"} disableInteractive>
                  <KeyboardArrowUpIcon />
                </Tooltip>
              </Fab>
            </ScrollTop>
          </Container>
          <Footer />
        </Box>
      </Box>
    </ThemeProvider>
  );
}
