import { useState, forwardRef } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
//import CardMedia from "@mui/material/CardMedia";
import defaulTheme from "../../components/themes/defaultTheme";
import { Formik, Form } from "formik";
import * as authActions from "../../redux/actions/auth.action";
//import BackgroundImage from "../../assets/images/bg-02.jpg";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import * as Yup from "yup";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      fontWeight={700}
      {...props}
    >
      Copyright &copy; 2022
    </Typography>
  );
}

const theme = createTheme(defaulTheme);

export default function SignUp() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const location = useLocation();
  const [openAlert, setOpenAlert] = useState(false);
  const [messageAlert, setMessageAlert] = useState("");
  const [severity, setSeverity] = useState("");
  const [backDrop, setBackDrop] = useState(false);

  const handleClickAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  const handleSignInClick = () => {
    navigate("/SignIn");
  };
  // const handleAutologinClick = () => {
  //   dispatch(authActions.autoLogin())
  //     .then((res) => {
  //       setBackDrop(true);
  //       console.log(res);
  //       if (res.isSuccess) {
  //         setTimeout(function () {
  //           setBackDrop(false);
  //           navigate("/Home");
  //         }, 1000);
  //       } else {
  //         setMessageAlert(res.message);
  //         setSeverity("error");
  //         handleClickAlert();
  //         setBackDrop(false);
  //         setTimeout(function () {
  //           handleCloseAlert();
  //         }, 3000);
  //       }
  //     })
  //     .catch((res) => {
  //       setMessageAlert(res.message);
  //       setSeverity("error");
  //       handleClickAlert();
  //       setBackDrop(false);
  //       setTimeout(function () {
  //         handleCloseAlert();
  //       }, 3000);
  //     });
  // };

  const validationSchema = Yup.object().shape({
    userName: Yup.string().required("Username is required").nullable(),
    password: Yup.string().required("Password is required").nullable(),
    confirmPassword: Yup.string()
     .oneOf([Yup.ref('password'), null], 'Passwords must match')
  });

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
          width: "100%",
          backgroundColor: "#829460",
          //backgroundImage: `url(${BackgroundImage})`,
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
          padding: "15px",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={backDrop}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            open={openAlert}
            onClose={handleCloseAlert}
          >
            <Alert
              onClose={handleCloseAlert}
              severity={severity}
              sx={{ width: "100%" }}
            >
              {messageAlert}
            </Alert>
          </Snackbar>
          <Box
            sx={{
              marginTop: 6,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Card
              variant="outlined"
              sx={{ boxShadow: 3, borderRadius: "16px", padding: 1 }}
            >
              <CardHeader sx={{ color: "#829460" }} title="SIGNUP" />
              <CardContent sx={{ padding: "16px 16px 8px 16px !important" }}>
                <Formik
                  enableReinitialize
                  initialValues={{
                    userName: "",
                    password: "",
                    firstName: "",
                    lastName: "",
                    phone: "",
                    email: "",
                    confirmPassword: "",
                  }}
                  onSubmit={(values) => {
                    console.log(values);
                    let formData = new FormData();
                    formData.append("userName", values.userName);
                    formData.append("password", values.password);
                    formData.append("firstName", values.firstName);
                    formData.append("lastName", values.lastName);
                    formData.append("phone", values.phone);
                    formData.append("email", values.email);

                    dispatch(authActions.signUp(formData))
                      .then((res) => {
                        setBackDrop(true);
                        console.log(res);
                        if (res.isSuccess) {
                          setTimeout(function () {
                            setBackDrop(false);
                            navigate("/SignIn");
                          }, 1000);
                        } else {
                          setMessageAlert(res.message);
                          setSeverity("error");
                          handleClickAlert();
                          setBackDrop(false);
                          setTimeout(function () {
                            handleCloseAlert();
                            //navigate("/Login");
                          }, 3000);
                        }
                      })
                      .catch((res) => {
                        setMessageAlert(res.message);
                        setSeverity("error");
                        handleClickAlert();
                        setBackDrop(false);
                        setTimeout(function () {
                          handleCloseAlert();
                          //navigate("/Login");
                        }, 3000);
                      });
                  }}
                  validationSchema={validationSchema}
                >
                  {(props) => {
                    const {
                      values,
                      touched,
                      errors,
                      handleChange,
                      handleSubmit,
                    } = props;
                    return (
                      <Form onSubmit={handleSubmit}>
                        {/* <Typography component="h1" variant="h5">
                          Please Sign In
                        </Typography> */}
                        <Grid container spacing={0.5}>
                          <Grid item xs={12} md={6}>
                            <TextField
                              id="firstName"
                              name="firstName"
                              fullWidth
                              margin="dense"
                              size="small"
                              autoFocus
                              value={values.firstName}
                              onChange={handleChange}
                              placeholder="FIRSTNAME"
                              helperText={
                                touched.firstName ? errors.firstName : ""
                              }
                              error={
                                touched.firstName && Boolean(errors.firstName)
                              }
                              sx={{
                                marginTop: 0,
                                "& .MuiOutlinedInput-root": {
                                  borderRadius: "16px",
                                  "& fieldset": {
                                    border: "2px solid rgba(0, 0, 0, 0.12)",
                                  },
                                  "&:hover fieldset": {
                                    border: "2px solid #90A17D",
                                  },
                                  "&.Mui-focused fieldset": {
                                    border: "2px solid #829460",
                                  },
                                },
                                "& .MuiFormHelperText-root.Mui-error": {
                                  fontWeight: 700,
                                  marginTop: 0,
                                },
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <TextField
                              id="lastName"
                              name="lastName"
                              fullWidth
                              margin="dense"
                              size="small"
                              value={values.lastName}
                              onChange={handleChange}
                              placeholder="LASTNAME"
                              helperText={
                                touched.lastName ? errors.lastName : ""
                              }
                              error={
                                touched.lastName && Boolean(errors.lastName)
                              }
                              sx={{
                                marginTop: 0,
                                "& .MuiOutlinedInput-root": {
                                  borderRadius: "16px",
                                  "& fieldset": {
                                    border: "2px solid rgba(0, 0, 0, 0.12)",
                                  },
                                  "&:hover fieldset": {
                                    border: "2px solid #90A17D",
                                  },
                                  "&.Mui-focused fieldset": {
                                    border: "2px solid #829460",
                                  },
                                },
                                "& .MuiFormHelperText-root.Mui-error": {
                                  fontWeight: 700,
                                  marginTop: 0,
                                },
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <TextField
                              id="phone"
                              name="phone"
                              fullWidth
                              margin="dense"
                              size="small"
                              value={values.phone}
                              onChange={handleChange}
                              placeholder="PHONE"
                              helperText={touched.phone ? errors.phone : ""}
                              error={touched.phone && Boolean(errors.phone)}
                              sx={{
                                marginTop: 0,
                                "& .MuiOutlinedInput-root": {
                                  borderRadius: "16px",
                                  "& fieldset": {
                                    border: "2px solid rgba(0, 0, 0, 0.12)",
                                  },
                                  "&:hover fieldset": {
                                    border: "2px solid #90A17D",
                                  },
                                  "&.Mui-focused fieldset": {
                                    border: "2px solid #829460",
                                  },
                                },
                                "& .MuiFormHelperText-root.Mui-error": {
                                  fontWeight: 700,
                                  marginTop: 0,
                                },
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <TextField
                              id="email"
                              name="email"
                              fullWidth
                              margin="dense"
                              size="small"
                              value={values.email}
                              onChange={handleChange}
                              placeholder="EMAIL"
                              helperText={touched.email ? errors.email : ""}
                              error={touched.email && Boolean(errors.email)}
                              sx={{
                                marginTop: 0,
                                "& .MuiOutlinedInput-root": {
                                  borderRadius: "16px",
                                  "& fieldset": {
                                    border: "2px solid rgba(0, 0, 0, 0.12)",
                                  },
                                  "&:hover fieldset": {
                                    border: "2px solid #90A17D",
                                  },
                                  "&.Mui-focused fieldset": {
                                    border: "2px solid #829460",
                                  },
                                },
                                "& .MuiFormHelperText-root.Mui-error": {
                                  fontWeight: 700,
                                  marginTop: 0,
                                },
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <TextField
                              id="userName"
                              name="userName"
                              fullWidth
                              margin="dense"
                              size="small"
                              value={values.userName}
                              onChange={handleChange}
                              placeholder="USERNAME"
                              helperText={
                                touched.userName ? errors.userName : ""
                              }
                              error={
                                touched.userName && Boolean(errors.userName)
                              }
                              sx={{
                                marginTop: 0,
                                "& .MuiOutlinedInput-root": {
                                  borderRadius: "16px",
                                  "& fieldset": {
                                    border: "2px solid rgba(0, 0, 0, 0.12)",
                                  },
                                  "&:hover fieldset": {
                                    border: "2px solid #90A17D",
                                  },
                                  "&.Mui-focused fieldset": {
                                    border: "2px solid #829460",
                                  },
                                },
                                "& .MuiFormHelperText-root.Mui-error": {
                                  fontWeight: 700,
                                  marginTop: 0,
                                },
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <TextField
                              id="password"
                              name="password"
                              type="password"
                              fullWidth
                              margin="dense"
                              size="small"
                              autoComplete="current-password"
                              value={values.password}
                              onChange={handleChange}
                              placeholder="PASSWORD"
                              helperText={
                                touched.password ? errors.password : ""
                              }
                              error={
                                touched.password && Boolean(errors.password)
                              }
                              sx={{
                                marginTop: 0,
                                "& .MuiOutlinedInput-root": {
                                  borderRadius: "16px",
                                  "& fieldset": {
                                    border: "2px solid rgba(0, 0, 0, 0.12)",
                                  },
                                  "&:hover fieldset": {
                                    border: "2px solid #90A17D",
                                  },
                                  "&.Mui-focused fieldset": {
                                    border: "2px solid #829460",
                                  },
                                },
                                "& .MuiFormHelperText-root.Mui-error": {
                                  fontWeight: 700,
                                  marginTop: 0,
                                },
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <TextField
                              id="confirmPassword"
                              name="confirmPassword"
                              type="password"
                              fullWidth
                              margin="dense"
                              size="small"
                              autoComplete="current-password"
                              value={values.confirmPassword}
                              onChange={handleChange}
                              placeholder="CONFIRMPASSWORD"
                              helperText={
                                touched.confirmPassword
                                  ? errors.confirmPassword
                                  : ""
                              }
                              error={
                                touched.confirmPassword &&
                                Boolean(errors.confirmPassword)
                              }
                              sx={{
                                marginTop: 0,
                                "& .MuiOutlinedInput-root": {
                                  borderRadius: "16px",
                                  "& fieldset": {
                                    border: "2px solid rgba(0, 0, 0, 0.12)",
                                  },
                                  "&:hover fieldset": {
                                    border: "2px solid #90A17D",
                                  },
                                  "&.Mui-focused fieldset": {
                                    border: "2px solid #829460",
                                  },
                                },
                                "& .MuiFormHelperText-root.Mui-error": {
                                  fontWeight: 700,
                                  marginTop: 0,
                                },
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <Button
                              type="submit"
                              fullWidth
                              variant="contained"
                              color="primary"
                              sx={{
                                mb: 1,
                                mt: 1,
                                borderRadius: "16px",
                              }}
                            >
                              <Typography variant="body2" fontWeight={700}>
                                SignIn
                              </Typography>
                            </Button>
                          </Grid>
                          <Grid item xs={12} md={6}></Grid>
                          <Grid item xs={12} md={6}>
                            <Button
                              type="button"
                              fullWidth
                              sx={{
                                borderRadius: "16px",
                              }}
                              onClick={() => handleSignInClick()}
                            >
                              <Typography variant="body2" fontWeight={700}>
                                Back to signin
                              </Typography>
                            </Button>
                          </Grid>
                        </Grid>
                      </Form>
                    );
                  }}
                </Formik>
              </CardContent>
            </Card>
          </Box>
          <Copyright sx={{ mt: 3, mb: 3 }} />
        </Container>
      </Box>
    </ThemeProvider>
  );
}
