const themes = {
  typography: {
    fontFamily: ["ntlregular"],
  },
  palette: {
    primary: {
      light: "#90A17D",
      main: "#829460",
      dark: "#829460",
      contrastText: "#fff",
    },
    secondary: {
      light: "#ff7961",
      main: "#f44336",
      dark: "#ba000d",
      contrastText: "#000",
    },
  },
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: "#90A17D",
          color: "#fff",
          "& .MuiTypography-root": {},
          "& .MuiListItemIcon-root": {
            color: "inherit",
          },
          "& .MuiListItemButton-root.Mui-selected": {
            backgroundColor: "#829460",
          },
          "& .MuiListItemButton-root:hover": {
            backgroundColor: "#829460",
            "&, & .MuiListItemIcon-root": {
              color: "#fff",
            },
          },
          "& .MuiListItemButton-root.Mui-selected:hover": {
            backgroundColor: "#829460",
            "&, & .MuiListItemIcon-root": {
              color: "#fff",
            },
          },
          "& .MuiDivider-root": {
            backgroundColor: "#829460",
            opacity: 0.3,
          },
          scrollbarWidth: "thin",
          scrollbarColor: "#90A17D transparent",
          "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
            backgroundColor: "transparent",
            width: 6,
            height: 6,
          },
          "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
            borderRadius: 6,
            backgroundColor: "#829460",
            minHeight: 24,
            minWidth: 24,
          },
          "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus":
            {
              backgroundColor: "#829460",
            },
          "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active":
            {
              backgroundColor: "#829460",
            },
          "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover":
            {
              backgroundColor: "#829460",
            },
          "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
            backgroundColor: "transparent",
          },
        },
      },
    },
  },
};

export default themes;
