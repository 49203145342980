import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as homeActions from "../../redux/actions/home.action";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Paper,
  Grid,
  Backdrop,
  Skeleton,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { styled } from "@mui/material/styles";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: "center",
  color: "#fff",
  height: 230,
  lineHeight: "230px",
  marginTop: "8px",
  fontSize: 48,
  borderRadius: "16px",
  background: "linear-gradient(to right, #829460, #90A17D)",
}));

export default function Home() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { result, isFetching } = useSelector((state) => state.homeReducer);

  useEffect(() => {
    const authUser = localStorage.getItem("authUser");
    if (authUser) {
      dispatch(homeActions.loadHome());
    } else {
      navigate("/SignIn");
    }
  }, [dispatch, navigate]);

  return (
    <Box sx={{ pt: 0.5 }}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isFetching}
      >
        <CircularProgress />
      </Backdrop>
      {isFetching && !result ? (
        <Card
          sx={{
            boxShadow: 3,
            borderRadius: "16px",
          }}
        >
          <CardContent>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <Paper sx={{ padding: "32px" }} elevation={0}>
                  <Typography component="div" variant="h3">
                    <Skeleton />
                  </Typography>
                  <Typography component="div" variant="h6">
                    <Skeleton />
                  </Typography>
                  <Typography component="div" variant="h6">
                    <Skeleton />
                  </Typography>
                  {/* <Skeleton
                  variant="rectangular"
                  sx={{ boxShadow: 3, borderRadius: "16px" }}
                  height={100}
                /> */}
                </Paper>
              </Grid>
              <Grid item xs={12} md={6}>
                <Paper sx={{ mt: 3, borderRadius: "16px" }} elevation={0}>
                  <Skeleton
                    variant="rectangular"
                    sx={{ boxShadow: 3, borderRadius: "16px" }}
                    height={230}
                  />
                </Paper>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      ) : (
        <Card
          sx={{
            boxShadow: 3,
            borderRadius: "16px",
          }}
        >
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Paper sx={{ padding: "32px" }} elevation={0}>
                  <Typography
                    variant="h3"
                    sx={{
                      background: "linear-gradient(to right, #829460, #CFFFDC)",
                      backgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                    }}
                  >
                    Welcome
                  </Typography>
                  <Typography variant="h6">สวัสดีครับ</Typography>
                </Paper>
              </Grid>
              <Grid item xs={12} md={6}>
                <Item elevation={3}>Total Movies : {result?.data?.totalMovies}</Item>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )}
    </Box>
  );
}
