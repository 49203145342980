import React from "react";
import Button from "@mui/material/Button";

function Copyright(props) {
  return (
    <footer className="app-footer">
      <span className="d-inline-block">Copyright &copy; 2022</span>
      <Button size="small" color="primary">
        V 1.0.0
      </Button>
    </footer>
  );
}

export default function index() {
  return <Copyright />;
}
