import React, { useState } from "react";
import { useDispatch } from "react-redux";
import MuiDrawer from "@mui/material/Drawer";
import { styled } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useNavigate, useLocation } from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import Typography from "@mui/material/Typography";
import { Avatar as AvatarMui } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import LogoutIcon from "@mui/icons-material/Logout";
import * as authActions from "../../redux/actions/auth.action";
import Divider from "@mui/material/Divider";
import EditIcon from "@mui/icons-material/Edit";
import PersonIcon from "@mui/icons-material/Person";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";

const drawerWidth = 240;

const CustomDrawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  marginTop: "30px",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Avatar = styled(AvatarMui)(({ theme }) => ({
  backgroundColor: "transparent",
  border: "2px solid #829460",
  boxShadow: 20
}));

export default function SideNav(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { toggleDrawer, handleDrawerToggle, open, mobileOpen, window, isMdUp } =
    props;
  const [selectedRoute, setselectedRoute] = useState(location.pathname);

  const currentUser = JSON.parse(localStorage.getItem("authUser"));

  const handleListItemClick = (event, route) => {
    event.preventDefault();
    setselectedRoute(route);
    if (!isMdUp) {
      handleDrawerToggle();
    }
    navigate(route);
  };

  const handleProfile = () => {
    navigate(`/User/UserProfile`);
  };

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const listMenu = (
    <List component="nav" sx={{ paddingLeft: "8px", paddingRight: `${open ? "8px" : "8px"}`, overflowY: "auto", overflowX: "hidden" }}>
      <ListItemButton
        sx={{ borderRadius: "20px" }}
        selected={selectedRoute === "/Home" || selectedRoute === "/"}
        onClick={(event) => handleListItemClick(event, "/Home")}
      >
        <ListItemIcon sx={{ ...(!open && {}) }}>
          <HomeIcon />
        </ListItemIcon>
        <ListItemText primary="Home" />
      </ListItemButton>
      <ListItemButton
        sx={{ borderRadius: "20px" }}
        selected={selectedRoute === "/ContactUs"}
        onClick={(event) => handleListItemClick(event, "/ContactUs")}
        //disabled
      >
        <ListItemIcon sx={{ ...(!open && {}) }}>
          <InfoIcon />
        </ListItemIcon>
        <ListItemText primary="Contact Us" />
      </ListItemButton>
      <ListItemButton
        sx={{ borderRadius: "20px" }}
        //selected={selectedRoute === "/about"}
        onClick={(event) => {
          let res = dispatch(authActions.signOut());
          if (res) {
            navigate("/SignIn", { state: { isLogout: true }, replace: false});
          }
        }}
      >
        <ListItemIcon sx={{ ...(!open && {}) }}>
          <LogoutIcon />
        </ListItemIcon>
        <ListItemText primary="Logout" />
      </ListItemButton>
    </List>
  );

  return isMdUp ? (
    <CustomDrawer variant={"permanent"} open={open}>
      <Toolbar
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          px: [1],
          mb: 6
        }}
      >
        <Typography component="div" sx={{ p: 1, textAlign: "center", width: "150px", height: open ? "80px" : "90px" }}>
          {/* <img
            src={require("../../assets/images/logoSmartCare.png")}
            alt="SmartCare"
            title="SmartCare"
            style={{ width: "150px", height: "60px" }}
          /> */}
        </Typography>
        <IconButton color="inherit" sx={{ mt: -2 }} onClick={toggleDrawer}>
          <ChevronLeftIcon />
        </IconButton>
      </Toolbar>
      <Paper
        elevation={open ? 6 : 0}
        sx={{
          m: `${open ? "10px" : ""}`,
          borderRadius: "16px",
          backgroundColor: "transparent",
          color: "#fff",
        }}
      >
        <DrawerHeader sx={{ mb: 1, mt: -8 }}>
          {open ? (
            <Avatar
              alt="SmartCare"
              src={require("../../assets/images/user.png")}
              sx={{ width: 120, height: 120, backgroundColor: "#829460", }}
            />
          ) : (
            <Avatar
              alt="SmartCare"
              src={require("../../assets/images/user.png")}
              sx={{ width: 56, height: 56, backgroundColor: "#829460", }}
            />
          )}
        </DrawerHeader>

        {open ? (
          <Box>
            <Typography
              noWrap
              component="div"
              sx={{ p: 1, textAlign: "center" }}
            >
              <PersonIcon fontSize="small" sx={{ mb: -0.5 }} />
              {currentUser ? currentUser.fullnameEN : ""}
            </Typography>
            <Stack direction="row" sx={{ mb: 2 }}>
              <Chip
                icon={<EditIcon />}
                size="small"
                label="Edit Profile"
                color="primary"
                sx={{ margin: "auto" }}
                //onClick={() => handleProfile()}
              />
            </Stack>
          </Box>
        ) : (
          ""
        )}
      </Paper>

      <Divider variant="middle" />
      {listMenu}
    </CustomDrawer>
  ) : (
    <Drawer
      container={container}
      variant="temporary"
      open={mobileOpen}
      onClose={handleDrawerToggle}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
      sx={{
        display: { xs: "block", sm: "block" },
        "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
      }}
    >
      <Typography component="div" sx={{ pt: 2, textAlign: "center", mb: 8, width: "150px", height: "110px" }}>
        {/* <img
          src={require("../../assets/images/logoSmartCare.png")}
          alt="SmartCare"
          title="SmartCare"
          style={{ width: "150px", height: "60px" }}
        /> */}
      </Typography>
      <Paper
        elevation={6}
        sx={{
          m: "10px",
          borderRadius: "16px",
          backgroundColor: "transparent",
          color: "#fff",
        }}
      >
        <DrawerHeader sx={{ mb: 1, mt: -8 }}>
          <Avatar
            alt="SmartCare"
            src={`data:image/jpg;base64,${
              currentUser?.pathImages ? currentUser?.pathImages : ""
            }`}
            sx={{ width: 120, height: 120 }}
          />
        </DrawerHeader>

        <Typography noWrap component="div" sx={{ p: 1, textAlign: "center" }}>
          <PersonIcon fontSize="small" sx={{ mb: -0.5 }} />
          {currentUser ? currentUser.fullnameEN : ""}
        </Typography>
        <Stack direction="row" sx={{ mb: 2 }}>
          <Chip
            icon={<EditIcon />}
            size="small"
            label="Edit Profile"
            color="primary"
            sx={{ margin: "auto" }}
            onClick={() => handleProfile()}
          />
        </Stack>
      </Paper>
      <Divider variant="middle" sx={{ color: "#829460" }} />
      {listMenu}
    </Drawer>
  );
}
