import {
  HOME_FETCHING,
  HOME_SUCCESS,
  HOME_FAILED,
  server,
} from "../../constants";
import { httpClient } from "../../utils/Api";

export const setHomeFetchingToState = () => ({
  type: HOME_FETCHING,
});

export const setHomeSuccessToState = (payload) => ({
  type: HOME_SUCCESS,
  payload,
});

export const setHomeFailedToState = (payload) => ({
  type: HOME_FAILED,
  payload,
});

export const loadHome = () => {
  return async (dispatch) => {
    dispatch(setHomeFetchingToState());
    await doLoadHome(dispatch);
  };
};

const doLoadHome = async (dispatch) => {
  try {
    const res = await httpClient.get(
      `${process.env.REACT_APP_API}/${server.HOME_URL}`
    );
    dispatch(setHomeSuccessToState(res.data));
  } catch (e) {
    dispatch(setHomeFailedToState("Failed"));
  }
};
