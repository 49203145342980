import {
    USER_FETCHING,
    USER_SUCCESS,
    USER_BY_ID_SUCCESS,
    USER_FAILED,
  } from "../../constants";
  
  const initialState = {
    result: null,
    isFetching: false,
    isError: false,
  };
  
  const User = (state = initialState, { type, payload }) => {
    switch (type) {
      case USER_FETCHING:
        return { ...state, isFetching: true, isError: false, result: null };
      case USER_SUCCESS:
        return { ...state, isFetching: false, isError: false, result: payload };
      case USER_BY_ID_SUCCESS:
          return { ...state, isFetching: false, isError: false, result: null, data: payload };
      case USER_FAILED:
        return { ...state, isFetching: false, isError: true, result: payload };
      default:
        return state;
    }
  };
  
  export default User;
  